import { useTheme } from '@/Hooks'
import { memo, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Text from '../Texts/Text'
import { formatNumber } from '@/Utils/formaters'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useProfileContext } from './Contexts/ProfileContext'
import { useGetFollowingsQuery } from '@/Services/modules/followings'
import { useGetFollowersQuery } from '@/Services/modules/followers'
import { useGetAnalyticsQuery } from '@/Services/modules/analytics'

const options = {
  refetchOnMountOrArgChange: true,
}

const Separator = styled.div`
  ${({ theme }) => css`
    width: 4px;
    height: 4px;
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    margin: auto ${theme.space.xs};
  `}
`

const Skeleton = styled.div`
  ${({ theme }) => css`
    width: 64px;
    height: 16px;
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    margin-right: ${theme.space.xs};
    margin-top: ${theme.space.xs};
  `}
`

const Stat = memo(({ count, label, onPress, last, completed }) => {
  const { theme } = useTheme()

  if (!completed) {
    return <Skeleton />
  }

  return (
    <div className="flex mt-1">
      <div onPress={onPress}>
        <Text color={theme.colors.primary}>
          {formatNumber(count)} {label}
        </Text>
      </div>
      {!last && <Separator />}
    </div>
  )
})

export const ProfileStats = memo(() => {
  const { t } = useTranslation()
  const { lang } = useSelector(state => state.language)
  const { profile = {}, isMine } = useProfileContext()
  // const route = useRoute()
  const { uid, hideFollowers, hideFollowing } = profile
  const { data: followings, isLoading: isLoadingFollowings } =
    useGetFollowingsQuery(uid, options)
  const { data: followers, isLoading: isLoadingFollowers } =
    useGetFollowersQuery(uid, options)
  const { data: views, isLoading: isLoadingViews } = useGetAnalyticsQuery(
    uid,
    options,
  )

  const completed = useMemo(
    () => !isLoadingFollowings && !isLoadingFollowers && !isLoadingViews,
    [isLoadingFollowings, isLoadingFollowers, isLoadingViews],
  )

  const shouldDisplayStats = useMemo(
    () => !hideFollowing || !hideFollowers,
    [hideFollowing, hideFollowers],
  )

  // const followersListName =
  //   route.name === 'Profile' ? 'FollowersList' : 'FollowersListModal'

  const spanishPluralCondition = lang === 'es' ? 'es' : lang === 'en' ? '' : 's'
  const pluralLanguage = 's'

  const stats = [
    ...(!hideFollowing
      ? [
          {
            // onPress: () =>
            //   navigatePush(followersListName, {
            //     tab: 'followings',
            //     profileUID: uid,
            //   }),
            count: followings?.length,
            label:
              followings?.length > 1
                ? `${t(
                    'previewpage.preview_stat_tabs.title_tabs1',
                  )}${spanishPluralCondition}`
                : t('previewpage.preview_stat_tabs.title_tabs1'),
          },
        ]
      : []),
    ...(!hideFollowers
      ? [
          {
            // onPress: () =>
            //   navigatePush(followersListName, {
            //     tab: 'followers',
            //     profileUID: uid,
            //   }),
            count: followers?.length,
            label:
              followers?.length > 1
                ? `${t(
                    'previewpage.preview_stat_tabs.title_tabs2',
                  )}${pluralLanguage}`
                : t('previewpage.preview_stat_tabs.title_tabs2'),
          },
        ]
      : []),
    ...(isMine
      ? [
          {
            // onPress: () => navigate('Settings', { screen: 'MyStats' }),
            count: views?.length,
            label:
              views?.length > 1
                ? `${t(
                    'previewpage.preview_stat_tabs.title_tabs3',
                  )}${pluralLanguage}`
                : t('previewpage.preview_stat_tabs.title_tabs3'),
          },
        ]
      : []),
  ]

  if (!shouldDisplayStats) {
    return null
  }

  return (
    <div className="flex text-sm items-center">
      {stats.map((stat, index) => {
        return (
          <Stat
            key={stat.label}
            {...stat}
            last={index === stats.length - 1}
            completed={completed}
          />
        )
      })}
    </div>
  )
})
